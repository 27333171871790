/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "./api";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const checkRfid = /* GraphQL */ `query CheckRfid($rfid: String!) {
  checkRfid(rfid: $rfid)
}
` as GeneratedQuery<APITypes.CheckRfidQueryVariables, APITypes.CheckRfidQuery>;
export const getChargerControl = /* GraphQL */ `query GetChargerControl($chargerId: Int, $transactionId: Int) {
  getChargerControl(chargerId: $chargerId, transactionId: $transactionId) {
    addedOn
    id
    isActive
    maxPower
    mode
    targetEnergy
    targetTimestamp
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetChargerControlQueryVariables,
  APITypes.GetChargerControlQuery
>;
export const getDataBoundaries = /* GraphQL */ `query GetDataBoundaries($dataSource: DataSource!, $rfid: String, $siteId: Int) {
  getDataBoundaries(dataSource: $dataSource, rfid: $rfid, siteId: $siteId)
}
` as GeneratedQuery<
  APITypes.GetDataBoundariesQueryVariables,
  APITypes.GetDataBoundariesQuery
>;
export const getParameter = /* GraphQL */ `query GetParameter($siteId: Int!) {
  getParameter(siteId: $siteId)
}
` as GeneratedQuery<
  APITypes.GetParameterQueryVariables,
  APITypes.GetParameterQuery
>;
export const listAlarms = /* GraphQL */ `query ListAlarms($includeAcked: Boolean, $siteId: Int!) {
  listAlarms(includeAcked: $includeAcked, siteId: $siteId) {
    ackSource
    ackTimestamp
    alarmTimestamp
    clearTimestamp
    description
    isActive
    siteId
    source
    value
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAlarmsQueryVariables,
  APITypes.ListAlarmsQuery
>;
export const listChargerStatusNotifications = /* GraphQL */ `query ListChargerStatusNotifications(
  $chargerId: Int!
  $connector: Int
  $endDate: Int
  $startDate: Int
) {
  listChargerStatusNotifications(
    chargerId: $chargerId
    connector: $connector
    endDate: $endDate
    startDate: $startDate
  ) {
    chargerId
    connector
    errorCode
    id
    info
    source
    statusCode
    timestamp
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListChargerStatusNotificationsQueryVariables,
  APITypes.ListChargerStatusNotificationsQuery
>;
export const listChargers = /* GraphQL */ `query ListChargers($changesSince: Int, $rfid: String, $siteId: Int) {
  listChargers(changesSince: $changesSince, rfid: $rfid, siteId: $siteId) {
    chargepoint
    connectionType
    coordinates
    firmware
    hasSmartCharging
    id
    isActive
    lastChange
    lastMessage
    model
    name
    nominalPower
    numberofconnectors
    restrictAuth
    serial
    tariffs {
      active
      priority
      tariffId
      __typename
    }
    vendor
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListChargersQueryVariables,
  APITypes.ListChargersQuery
>;
export const listChargingSessions = /* GraphQL */ `query ListChargingSessions(
  $chargerId: Int
  $connector: Int
  $endDate: Int
  $rfid: String
  $siteId: Int
  $startDate: Int
  $user: String
) {
  listChargingSessions(
    chargerId: $chargerId
    connector: $connector
    endDate: $endDate
    rfid: $rfid
    siteId: $siteId
    startDate: $startDate
    user: $user
  ) {
    chargerId
    connector
    costs
    id
    rfid
    rfidId
    siteId
    startDate
    startMeter
    stopDate
    stopMeter
    stopReason
    tariffId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListChargingSessionsQueryVariables,
  APITypes.ListChargingSessionsQuery
>;
export const listConnectors = /* GraphQL */ `query ListConnectors($changesSince: Int, $rfid: String, $siteId: Int) {
  listConnectors(changesSince: $changesSince, rfid: $rfid, siteId: $siteId) {
    chargerId
    connectorId
    connectorStatus
    current
    energymeter
    evseid
    isDc
    lastChange
    lastUpdate
    name
    nominalPower
    nrPhases
    offeredCurrent
    offeredPower
    plugType
    power
    sessionenergy
    soc
    voltage
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListConnectorsQueryVariables,
  APITypes.ListConnectorsQuery
>;
export const listImportFiles = /* GraphQL */ `query ListImportFiles($siteId: Int!) {
  listImportFiles(siteId: $siteId) {
    addedOn
    fileType
    id
    result
    status
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListImportFilesQueryVariables,
  APITypes.ListImportFilesQuery
>;
export const listIoData = /* GraphQL */ `query ListIoData(
  $aggregation: Aggregation
  $aggregationType: AggregationType
  $dataSource: DataSource!
  $endDate: Int
  $ioList: [IoAddress!]
  $siteId: Int!
  $startDate: Int
) {
  listIoData(
    aggregation: $aggregation
    aggregationType: $aggregationType
    dataSource: $dataSource
    endDate: $endDate
    ioList: $ioList
    siteId: $siteId
    startDate: $startDate
  ) {
    bus
    channel
    count
    module
    siteId
    timestamp
    value
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListIoDataQueryVariables,
  APITypes.ListIoDataQuery
>;
export const listRefusedRfids = /* GraphQL */ `query ListRefusedRfids($siteId: Int!) {
  listRefusedRfids(siteId: $siteId) {
    charger
    counter
    firstAttempt
    id
    lastAttempt
    rfid
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListRefusedRfidsQueryVariables,
  APITypes.ListRefusedRfidsQuery
>;
export const listRfids = /* GraphQL */ `query ListRfids($changesSince: Int, $rfid: String, $siteId: Int) {
  listRfids(changesSince: $changesSince, rfid: $rfid, siteId: $siteId) {
    accessRights
    category
    chargerRestricted
    chargers
    id
    isActive
    lastChange
    lastUsage
    limitCost
    limitEnd
    limitEnergy
    limitPeriod
    limitStart
    maxPower
    name
    rfid
    siteId
    siteIds
    tariffs {
      active
      priority
      tariffId
      __typename
    }
    usedCost
    usedEnergy
    __typename
  }
}
` as GeneratedQuery<APITypes.ListRfidsQueryVariables, APITypes.ListRfidsQuery>;
export const listSites = /* GraphQL */ `query ListSites {
  listSites {
    cloudSettings
    company
    deviceSw
    deviceType
    id
    ipAddresses
    latitude
    longitude
    name
    serial
    tzOffset
    __typename
  }
}
` as GeneratedQuery<APITypes.ListSitesQueryVariables, APITypes.ListSitesQuery>;
export const listTariffs = /* GraphQL */ `query ListTariffs($endDate: Int, $rfid: String, $siteId: Int, $startDate: Int) {
  listTariffs(
    endDate: $endDate
    rfid: $rfid
    siteId: $siteId
    startDate: $startDate
  ) {
    costPerEnergy
    costPerPeriod
    id
    isActive
    name
    priority
    siteId
    timeEnd
    timeStart
    validFrom
    validUntil
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTariffsQueryVariables,
  APITypes.ListTariffsQuery
>;
export const listTrendData = /* GraphQL */ `query ListTrendData(
  $aggregation: Aggregation
  $chargerId: Int
  $endDate: Int
  $rfid: String
  $siteId: Int!
  $startDate: Int
  $tlId: [Int!]
) {
  listTrendData(
    aggregation: $aggregation
    chargerId: $chargerId
    endDate: $endDate
    rfid: $rfid
    siteId: $siteId
    startDate: $startDate
    tlId: $tlId
  ) {
    chargerId
    costs
    energy
    energymeter
    energyoffrel
    maxdisabled
    pmax
    power
    regperiod
    siteId
    soc
    targetenergy
    tariff
    timestamp
    tlId
    trendpmax
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTrendDataQueryVariables,
  APITypes.ListTrendDataQuery
>;
export const listUsers = /* GraphQL */ `query ListUsers($userIds: [Int!]) {
  listUsers(userIds: $userIds) {
    accessRights
    chargingControl
    company
    email
    id
    name
    role
    secondaryId
    settings
    siteIds
    __typename
  }
}
` as GeneratedQuery<APITypes.ListUsersQueryVariables, APITypes.ListUsersQuery>;
export const triggerReport = /* GraphQL */ `query TriggerReport(
  $customization: String
  $outputFormat: ReportFormat!
  $reportId: Int!
  $siteId: Int!
) {
  triggerReport(
    customization: $customization
    outputFormat: $outputFormat
    reportId: $reportId
    siteId: $siteId
  )
}
` as GeneratedQuery<
  APITypes.TriggerReportQueryVariables,
  APITypes.TriggerReportQuery
>;
